<template>
    <div class="flex">
        <div v-for="data, key in data" :key="key" class="w-52 mr-2 mt-2 lg:mt-0 text-black">
            <div class="px-3 py-4 bg-grays-100 flex items-center cursor-pointer" @click="openFile(data)">
                <img src="@/assets/img/pdf_icon.svg" width="35px" alt="">
                <div class="ml-3 mt-0">
                    <h3 class="text-sm font-semibold">{{ data.name }}</h3>
                    <h5 class="text-xs font-normal">{{ data.date | moment }}</h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    props: [
        'data'
    ],
    filters: {
        moment: function (date) {
            return moment(date).format('DD MMM YYYY');
        }
    },
    computed: {
        dataCard() {
            return this.data.details
        }
    },
    methods: {
        openFile(data) {
            window.open(data.filename);
            // console.log(data); 
        }
    },
}
</script>

<style>

</style>