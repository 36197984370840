<template>
  <div class="px-2 komisaris lg:px-0">
      <p class="mb-8 text-justify" v-html="tatakelolaData.commissioner"></p>
      <p @click="openItem(tatakelolaData.guide2)" class="underline cursor-pointer">Download Pedoman Komisaris</p>
      <h5 class="text-center" v-if="!Array.isArray(getDataPresence) || !getDataPresence.length"></h5>
      <div v-else>
            <DataTable
                title=""
                :columns="tableColumns"
                :rows="getDataPresence"
                :clickable="true"
                :sortable="true"
                :exactSearch="true"
                :searchable="true"
                :paginate="false"
                :exportable="false"
                :printable="false"
                v-on:row-click="onRowClick"
                v-if="lang == 'id'"
            />
            <DataTable
                title=""
                :columns="tableColumnsEng"
                :rows="getDataPresence"
                :clickable="true"
                :sortable="true"
                :exactSearch="true"
                :searchable="true"
                :paginate="false"
                :exportable="false"
                :printable="false"
                v-on:row-click="onRowClick"
                v-else
            />
      </div>

      <div>
          <div class="my-8">
              <div v-if="getData == null"></div>
              <div 
                v-else
                v-for="data, i in sortProfile"
                :key="i"
                class="my-8"
              >                  
                  <Profile :profile-data="data" />
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import Profile from "./Profile.vue";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import DataTable from "vue-materialize-datatable";
import axios from 'axios'

export default {
  components: {
    DataTable,
    Profile
  },
  props:[
    'tatakelolaData',
    'lang'
  ],
  data() {
    return {
      tableColumns: [
          {
              label: "Nama",
              field: "name",
              numeric: false,
              html: false
          },
          {
              label: "Jumlah Kehadiran",
              field: "absence",
              numeric: false,
              html: false
          },
          {
              label: "%",
              field: "absence_percentage",
              numeric: false,
              html: false
          }
      ],
      tableColumnsEng: [
          {
              label: "Nama",
              field: "name",
              numeric: false,
              html: false
          },
          {
              label: "Jumlah Kehadiran",
              field: "absence_eng",
              numeric: false,
              html: false
          },
          {
              label: "%",
              field: "absence_percentage_eng",
              numeric: false,
              html: false
          }
      ],
      getData: null,
      getDataPresence: null
    }
  },
  mounted() {
    this.getProfileData();
    this.getMeeting();
  },
  computed: {
    sortProfile() {
      return this.getData.sort((a, b) => parseFloat(a.level) - parseFloat(b.level));
    }
  },
  methods: {
    onRowClick(row){
        console.log(row)
        this.$router.push(
            { 
                name: "View"
            }
        )
    },
    async getProfileData() {
        try {
            axios
            .get('https://api.dharmagroup.co.id/api/profile/datatable/commissioner', {
                'headers': { 'Authorization': 'Bearer 71063a7eab248181eafe665bf4ae346f-sFqsGThLIgEL9Kvjo0NZInsTou2NMoexYTnPwVjXAgV1K2scDuW58jBBy53'} })
            .then((res) => {
                // console.log(res);
                this.getData = res.data.data
            })
        } catch (error) {
            this.$store.commit('toast/NEW', { type: 'error', message: error.message })
        }
    },
    async getMeeting() {
        try {
            axios
            .get('https://api.dharmagroup.co.id/api/meeting/datatable', {
                'headers': { 'Authorization': 'Bearer 71063a7eab248181eafe665bf4ae346f-sFqsGThLIgEL9Kvjo0NZInsTou2NMoexYTnPwVjXAgV1K2scDuW58jBBy53'} })
            .then((res) => {
                // console.log(res);
                this.getDataPresence = res.data.data
            })
        } catch (error) {
            this.$store.commit('toast/NEW', { type: 'error', message: error.message })
        }
    },
    openItem(i) {
        window.open(`https://api.dharmagroup.co.id${i}`);
    }
  }
}
</script>

<style lang="scss">
    .komisaris{
        .material-table{
            position: relative;
            padding-top: 72px !important;

            .table-header{
                border: none;
                width: 78px;
                position: absolute;
                right: -11px;
                top: 27px;
                z-index: 10;
                height: 48px;
            }
        }

        table {
            @apply w-full mt-5;

            thead{
                background-color: #312963;

                th{
                    @apply text-grays-50 text-base font-bold
                }
                .sorting-desc, .sorting-asc{
                    @apply text-primary
                }
            }

           
        }

        #search-input-container{
            @apply absolute  my-2 border-none w-80 -right-3 top-5 mr-0;
            

            input{
                @apply  shadow rounded   p-3 w-full pr-10 outline-none;
                
            }

            #search-input{
                border:1px solid #212121 !important;
                height: 42px !important;
            }
        }
    }
</style>