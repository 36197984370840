<template>
  <div>
        <div v-if="profileData == null">Loading</div>
        <div v-else>
            <div class="flex justify-between px-4 py-2 font-semibold text-white cursor-pointer bg-primary" @click="accorEvent">
                <div>
                    {{ profileData.name }}, {{profileData.position}} 
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 h-4 feather feather-chevron-up" :class="{'transY':seen}">
                    <polyline points="18 15 12 9 6 15"></polyline>
                </svg>
            </div>
            <div
                class="px-0 md:px-6"
                v-show="seen"
            >
                <div>
                    <img :src="profileData.picture" :alt="profileData.picture" width="233" height="233" class="my-8 rounded-2xl">
                </div>
                <ul>
                    <li class="flex flex-col items-start my-2 md:flex-row">
                        <span v-if="lang == 'id'" class="w-full font-semibold md:w-3/12">Kewarganegaraan :</span>
                        <span v-else class="w-full font-semibold md:w-3/12">Citizenship :</span>
                        <span class="w-full md:w-8/12" v-html="profileData.citizenship"></span>
                    </li>
                    <li class="flex flex-col items-start my-2 md:flex-row">
                        <span v-if="lang == 'id'" class="w-full font-semibold md:w-3/12">Usia :</span>
                        <span v-else class="w-full font-semibold md:w-3/12">Age :</span>
                        <span class="w-full md:w-8/12" v-html="profileData.age"></span>
                    </li>
                    <li class="flex flex-col items-start my-2 md:flex-row">
                        <span v-if="lang == 'id'" class="w-full font-semibold md:w-3/12">Riwayat Pendidikan :</span>
                        <span v-else class="w-full font-semibold md:w-3/12">Education :</span>
                        <span v-if="lang == 'id'" class="w-full md:w-8/12" v-html="profileData.education"></span>
                        <span v-else class="w-full md:w-8/12" v-html="profileData.education_eng"></span>
                    </li>
                    <li class="flex flex-col items-start my-2 md:flex-row">
                        <span v-if="lang == 'id'" class="w-full font-semibold md:w-3/12">Dasar Hukum Pengangkatan di PT Dharma :</span>
                        <span v-else class="w-full font-semibold md:w-3/12">PT Dharma Legal Basis of Appointment :</span>
                        <span v-if="lang == 'id'" class="w-full md:w-8/12" v-html="profileData.law"></span>
                        <span v-else class="w-full md:w-8/12" v-html="profileData.law_eng"></span>
                    </li>
                    <li class="my-2 list-none">
                        <span v-if="lang == 'id'" class="text-lg font-semibold text-secondary">Riwayat Jabatan</span>
                        <span v-else class="text-lg font-semibold text-secondary">Job History</span>
                    </li>
                    <li class="flex flex-col items-start my-2 md:flex-row">
                        <span v-if="lang == 'id'" class="w-full font-semibold md:w-3/12">Pengalaman Kerja :</span>
                        <span v-else class="w-full font-semibold md:w-3/12">Work Experience :</span>
                        <span v-if="lang == 'id'" class="w-full md:w-8/12" v-html="profileData.career"></span>
                        <span v-else class="w-full md:w-8/12" v-html="profileData.career_eng"></span>
                    </li>
                    <li class="flex flex-col items-start my-2 md:flex-row">
                        <span v-if="lang == 'id'" class="w-full font-semibold md:w-3/12">Jabatan Rangkap :</span>
                        <span v-else class="w-full font-semibold md:w-3/12">Double Function :</span>
                        <span v-if="lang == 'id'" class="w-full md:w-8/12" v-html="profileData.training"></span>
                        <span v-else class="w-full md:w-8/12" v-html="profileData.training_eng"></span>
                    </li>
                    <li class="flex flex-col items-start my-2 md:flex-row">
                        <span v-if="lang == 'id'" class="w-full font-semibold md:w-3/12">Hubungan Afiliasi :</span>
                        <span v-else class="w-full font-semibold md:w-3/12">Affiliate Relationships :</span>
                        <span v-if="lang == 'id'" class="w-full md:w-8/12" v-html="profileData.affiliate"></span>
                        <span v-else class="w-full md:w-8/12" v-html="profileData.affiliate_eng"></span>
                    </li>
                    <li class="flex flex-col items-start my-2 md:flex-row">
                        <span v-if="lang == 'id'" class="w-full font-semibold md:w-3/12">Domisili :</span>
                        <span v-else class="w-full font-semibold md:w-3/12">Domicile :</span>
                        <span class="w-full md:w-8/12" v-html="profileData.domicile"></span>
                    </li>
                    <li class="flex flex-col items-start my-2 md:flex-row">
                        <span v-if="lang == 'id'" class="w-full font-semibold md:w-3/12">Jumlah Kepemilikan Saham :</span>
                        <span v-else class="w-full font-semibold md:w-3/12">Total Share Ownership :</span>
                        <span class="w-full md:w-8/12" v-html="profileData.share"></span>
                    </li>
                </ul>
            </div>
        </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    props: [
        'profileData'
    ],
    data() {
        return {
            seen: false
        }
    },
    computed: {
        ...mapState({
            lang: state => state.home.lang ? state.home.lang :'id',
        }),
    },
    methods: {
        accorEvent() {
            console.log('clicked');
            this.seen = !this.seen
        },
    }
}
</script>

<style>
    .transY{
        transform: rotate(180deg);
    }
</style>