<template>
  <div class="px-2 lg:px-0">
      <p class="mb-8 text-justify" v-html="tatakelolaData.support"></p>
      <div>
        <div class="my-8">
            <div v-if="getData == null"></div>
            <div 
              v-else
              v-for="data, i in sortProfile"
              :key="i"
              class="my-8"
            >                  
                <Profile :profile-data="data" />
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Profile from "./Profile.vue";
import axios from 'axios'

export default {
  components: {
    Profile
  },
  props:[
    'tatakelolaData',
    'lang'
  ],
  data() {
    return {
      getData: null
    }
  },
  mounted() {
    this.getProfileData();
  },
  computed: {
    sortProfile() {
      return this.getData.sort((a, b) => parseFloat(a.level) - parseFloat(b.level));
    }
  },
  methods: {
    async getProfileData() {
        try {
            axios
            .get('https://api.dharmagroup.co.id/api/profile/datatable/support', {
                'headers': { 'Authorization': 'Bearer 71063a7eab248181eafe665bf4ae346f-sFqsGThLIgEL9Kvjo0NZInsTou2NMoexYTnPwVjXAgV1K2scDuW58jBBy53'} })
            .then((res) => {
                // console.log(res);
                this.getData = res.data.data
            })
        } catch (error) {
            this.$store.commit('toast/NEW', { type: 'error', message: error.message })
        }
    }
  }
}
</script>

<style>

</style>