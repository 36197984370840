<template>
  <div class="px-2 lg:px-0">
      <p class="mb-8 text-justify" v-html="tatakelolaData.fact"></p>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props:[
    'tatakelolaData',
    'lang'
  ],
  data() {
      return {
      getData: null
      }
  },
  mounted() {
      axios
      .get('https://api.dharmagroup.co.id/api/about', {
          'headers': { 'Authorization': 'Bearer 71063a7eab248181eafe665bf4ae346f-sFqsGThLIgEL9Kvjo0NZInsTou2NMoexYTnPwVjXAgV1K2scDuW58jBBy53'} })
      .then((res) => {
      console.log(res);
      this.getData = res.data.data
      })
  }
}
</script>

<style>

    ol{
        @apply list-decimal;
    }

</style>