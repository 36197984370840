<template>
    <div class="tatakelola">
        <carousel
            :nav = true
            :centerMode = true
            :autoPlay = true
            :playSpeed = 8000
            :dtImg = banner
	    />
        <div class="w-full h-20 overflow-x-scroll bg-grays-100 md:h-24 text-grays-400">
            <ul class="flex items-center justify-between h-full lg:px-32">
                <li 
                    v-for="data, i in tabMenu" 
                    :key="i"
                    class="flex flex-wrap items-center justify-center w-40 h-full px-2 mx-3 text-xs font-semibold capitalize duration-100 border-b-4 border-solid cursor-pointer font-pop lg:text-sm text-grays-400 border-grays-200 hover:border-primary hover:text-primary"
                    :class="{'activeTab' : i == activeTab }" 
                    @click="tabNav(i)" 
                >
                    <span v-if="lang == 'id'"  v-bind:class="{ 'italic' : data.title === 'Whistle Blowing System'}">
                        {{ data.title }}
                    </span>
                    <span v-else>
                        {{ data.title_eng }}
                    </span>
                </li>
            </ul>
        </div>
        <div class="max-w-screen-xl px-2 mx-auto mb-32 text-left lg:px-32">
            <div class="px-2 my-6 lg:px-0">
                <!-- <h5 v-if="lang == 'id'" class="text-lg">Tata Kelola Perusahaan</h5>
                <h5 v-else class="text-lg">Corporate Governance</h5> -->
                <h3 v-if="lang == 'id'" class="text-xl font-semibold">{{ whichTab.title }}</h3>
                <h3 v-else class="text-xl font-semibold">{{ whichTab.title_eng }}</h3>
            </div>
            <Rupst v-if="activeTab == 0"
                :tatakelolaData="tatakelolaData"
                :lang="lang"
            />
            <DewanKomisaris v-if="activeTab == 1"
                :tatakelolaData="tatakelolaData"
                :lang="lang"
            />
            <Direksi v-if="activeTab == 2"
                :tatakelolaData="tatakelolaData"
                :lang="lang"
            />
            <OrganPendukung v-if="activeTab == 3"
                :tatakelolaData="tatakelolaData"
                :lang="lang"
            />
            <Komunikasi v-if="activeTab == 4"
                :tatakelolaData="tatakelolaData"
                :lang="lang"
            />
            <Lembar v-if="activeTab == 5"
                :tatakelolaData="tatakelolaData"
                :lang="lang"
            />
            <KeterbukaanInfo v-if="activeTab == 6"
                :tatakelolaData="tatakelolaData"
                :lang="lang"
            />
            <ManajemenResiko v-if="activeTab == 7"
                :tatakelolaData="tatakelolaData"
                :lang="lang"
            />
        </div>
    </div>
</template>

<script>
import carousel from "@/components/carousel.vue";
import Rupst from "@/components/tatakelola/Rupst.vue";
import DewanKomisaris from "@/components/tatakelola/DewanKomisaris.vue";
import Direksi from "@/components/tatakelola/Direksi.vue";
import OrganPendukung from "@/components/tatakelola/OrganPendukung.vue";
import Komunikasi from "@/components/tatakelola/Komunikasi.vue";
import Lembar from "@/components/tatakelola/Lembar.vue";
import KeterbukaanInfo from "@/components/tatakelola/KeterbukaanInfo.vue";
import ManajemenResiko from "@/components/tatakelola/ManajemenResiko.vue";
import { mapState } from 'vuex';

export default {
    components: {
        Rupst,
        DewanKomisaris,
        Direksi,
        OrganPendukung,
        Komunikasi,
        Lembar,
        KeterbukaanInfo,
        ManajemenResiko,
        carousel
    },
    data() {
        return {
            activeTab: 0,
            tabMenu: [
                {
                    title: 'RUPST dan RUPSLB',
                    title_eng: 'AGMS and EGMS',
                    name: 'RUPST'
                },
                {
                    title: 'Dewan Komisaris',
                    title_eng: 'Board of Commissioners',
                    name: 'dewankomisaris'
                },
                {
                    title: 'Direksi',
                    title_eng: 'Board of Directors',
                    name: 'direksi'
                },
                {
                    title: 'Organ Pendukung',
                    title_eng: 'Supporting Organ',
                    name: 'organpendukung'
                },
                {
                    title: 'Komunikasi dengan pemegang saham',
                    title_eng: 'Communication with Shareholders',
                    name: 'komunikasi'
                },
                {
                    title: 'Whistle Blowing System',
                    title_eng: 'Whistle Blowing System',
                    name: 'lembar'
                },
                {
                    title: 'Keterbukaan Informasi',
                    title_eng: 'Information Disclosure',
                    name: 'keterbukaaninfo'
                },
                {
                    title: 'Manajemen Risiko',
                    title_eng: 'Risk Management',
                    name: 'manajemenresiko'
                }
            ]
        }
    },
    created() {
        // console.log(this.$route.params.data);
        let routeParam = this.$route.params.data

        if (routeParam) {
            this.activeTab = routeParam
        } else {
            this.activeTab = 0
        }
        document.addEventListener('beforeunload', this.destroyTabParam)
    },
    methods: {
        tabNav(i) {
            // console.log(this.tabMenu[i]);
            this.activeTab = i
        },
        destroyTabParam() {
            this.activeTab = 0
            // console.log(destroyed);
        }
    },
    computed: {
        whichTab() {
            return this.tabMenu[this.activeTab]
        },
        ...mapState({
            data: state => state.home.home ? state.home.home.data :'',
            lang: state => state.home.lang ? state.home.lang :'id',
        }),
        tatakelolaData(){
            let data_id = {}
            let data_eng= {}

            if (this.lang == 'id'){
				Object.assign(data_id, 
					{rupst: this.data.rupst},
					{commissioner: this.data.commissioner},
					{director: this.data.director},
					{support: this.data.support},
					{shareholder: this.data.shareholder},
					{fact: this.data.fact},
					{transparency: this.data.transparency},
                    {risk: this.data.risk},
					{communication: this.data.communication},
                    {guide: this.data.guide_doc ? this.data.guide_doc.director  :'' },
                    {guide2: this.data.guide_doc ? this.data.guide_doc.commissioner  :'' }
				);
				return data_id
			}else{
				Object.assign(data_eng, 
					{rupst: this.data.rupst_eng},
					{commissioner: this.data.commissioner_eng},
					{director: this.data.director_eng},
					{support: this.data.support_eng},
					{shareholder: this.data.shareholder_eng},
					{fact: this.data.fact_eng},
					{transparency: this.data.transparency_eng},
                    {risk: this.data.risk_eng},
                    {communication: this.data.communication_eng},
                    {guide: this.data.guide_doc ? this.data.guide_doc.director  :'' },
                    {guide2: this.data.guide_doc ? this.data.guide_doc.commissioner  :'' }
				);
				return data_eng
			}
        },
        banner(){
			return this.$store.getters['image/imgWtFilter']('manage_banner')
		}
    }
}
</script>

<style>
    .activeTab{
        @apply bg-primary text-white border-primary hover:text-grays-50;
    }
</style>